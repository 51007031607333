<template>
  <div v-if="!isFormSubmitted">
    <b-card>
      <b-form @submit.prevent v-if="showForm">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="name"
              :label="$t(`flat-flyer-pricing.name-label`)"
              label-for="name"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.name.$error }"
            >
              <b-form-input
                id="name"
                v-model="formData.name"
                :placeholder="$t(`flat-flyer-pricing.name-placeholder`)"
                trim
                :class="{ 'is-invalid': $v.formData.name.$error }"
              ></b-form-input>
              <b-form-invalid-feedback id="name-feedback">
                {{ $t(`flat-flyer-pricing.name-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="siteId"
              :label="$t(`flat-flyer-pricing.select_site-label`)"
              label-for="siteId"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.name.$error }"
            >
              <b-dropdown
                id="dropdown-form"
                :text="
                  formData.siteId == ''
                    ? $t(`flat-flyer-pricing.select_site-placeholder`)
                    : selectedSite.Name
                "
                ref="dropdown"
                block
                menu-class="w-100 scrollable"
                no-caret
                lazy
                variant="brand-dropdown"
                :toggle-class="{ 'is-invalid': $v.formData.siteId.$error }"
                :class="{ 'is-invalid': $v.formData.siteId.$error }"
              >
                <b-dropdown-item
                  v-for="(site, index) in sitesList"
                  :key="index"
                  @click="formData.siteId = site.value"
                  :active="formData.siteId == site.value"
                  >{{ site.text }}</b-dropdown-item
                >
              </b-dropdown>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback id="siteId-feedback">
                {{ $t(`flat-flyer-pricing.select_site-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <div role="group" class="mb-4">
          <label for="chooseProducts" class="form-label required">{{
            $t(`flat-flyer-pricing.choose_product-label`)
          }}</label>
          <i class="icon-tooltip ml-1" id="chooseProducts-tootltip"></i>
          <b-tooltip target="chooseProducts-tootltip"
            ><p v-html="$t(`flat-flyer-pricing.choose_product-tooltip`)"
          /></b-tooltip>
          <div class="product-filters" id="productFilters">
            <product-filters
              filterLabel="Choose products"
              v-for="(productGroup, index) in productGroups"
              :key="index"
              :id="productGroup.groupId"
              :index="index"
              :totalGroups="productGroups.length"
              :requiredFilters="requiredFilters"
              :disabled="formData.siteId == ''"
              @removeProductGroup="removeProductGroup"
              :class="{
                'single-filter':
                  !enableProductGroupBoxStyling && !enableAddProductGroup,
                'is-invalid':
                  formData.siteId != '' &&
                  $v.productGroups.isInvalidProductGroup &&
                  $v.productGroups.$dirty,
              }"
              moduleSlug="flat-flyer-pricing"
              :controllerName="controllerName"
              :moduleName="moduleName"
              :isPushPricing="isPushPricing"
            />
            <b-form-invalid-feedback>
              {{
                !enableProductGroupBoxStyling && !enableAddProductGroup
                  ? sytemMessages.productGroupRequired
                  : sytemMessages.productGroupsRequired + availableProductGroups
              }}
            </b-form-invalid-feedback>
          </div>
          <b-button
            variant="link"
            @click="createNewProductGroup"
            v-if="enableProductGroupBoxStyling && enableAddProductGroup"
            :disabled="formData.siteId == ''"
            >Add New Product-Group</b-button
          >
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div id="startDate" role="group" class="mb-4">
              <label for="startDate" class="form-label required">{{
                $t(`flat-flyer-pricing.start_date-label`)
              }}</label>
              <div
                class="flex w-full"
                :class="{ 'is-invalid': $v.formData.priceStartDate.$error }"
              >
                <v-date-picker
                  :locale="selected_language"
                  ref="priceStartDate"
                  class="flex-grow"
                  :class="{ 'is-invalid': $v.formData.priceStartDate.$error }"
                  :masks="masks"
                  v-model="formData.priceStartDate"
                  is-required
                  :popover="{ visibility: 'click' }"
                  :model-config="modelConfig"
                  :disabled-dates="[
                    {
                      start: null,
                      end: yesterday,
                    },
                  ]"
                  :available-dates="{
                    start: new Date(formData.priceStartDate),
                    end: new Date(formData.priceStartDate),
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <div class="d-flex custom-datepicker">
                      <input
                        class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                        :class="{
                          'is-invalid': $v.formData.priceStartDate.$error,
                        }"
                        :value="inputValue"
                        @click="showStartDatePicker"
                        :placeholder="dateFormat"
                        readonly
                      />
                      <div
                        class="picker-icon"
                        :class="{
                          'is-invalid': $v.formData.priceStartDate.$error,
                        }"
                        slot="afterDateInput"
                        role="button"
                        @click="showStartDatePicker"
                      >
                        <i class="icon-date"></i>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <b-form-invalid-feedback id="startDate-feedback">
                {{ sytemMessages.startDateRequired }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div id="endDate" role="group" class="mb-4">
              <label for="endDate" class="form-label">{{
                $t(`flat-flyer-pricing.end_date-label`)
              }}</label>
              <div
                class="flex w-full"
                :class="{ 'is-invalid': $v.formData.priceEndDate.$error }"
              >
                <v-date-picker
                  :locale="selected_language"
                  ref="priceEndDate"
                  class="flex-grow"
                  :masks="masks"
                  v-model="formData.priceEndDate"
                  :popover="{ visibility: 'click' }"
                  :model-config="modelConfig"
                  :disabled-dates="[
                    {
                      start: null,
                      end: yesterday,
                    },
                  ]"
                  :available-dates="{
                    start:
                      formData.priceEndDate != ''
                        ? new Date(formData.priceEndDate)
                        : new Date(),
                    end:
                      formData.priceEndDate != ''
                        ? new Date(formData.priceEndDate)
                        : null,
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <div class="d-flex custom-datepicker">
                      <input
                        class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                        :class="{
                          'is-invalid': $v.formData.priceEndDate.$error,
                        }"
                        :value="inputValue"
                        @click="showEndDatePicker"
                        :placeholder="dateFormat"
                        readonly
                      />
                      <div
                        class="picker-icon"
                        :class="{
                          'is-invalid': $v.formData.priceEndDate.$error,
                        }"
                        slot="afterDateInput"
                        role="button"
                        @click="showEndDatePicker"
                      >
                        <i class="icon-date"></i>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <b-form-invalid-feedback id="priceEndDate-feedback">
                {{ $t(`flat-flyer-pricing.end_date-invalid-error-msg`) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </b-form>

      <preview-form
        :formName="formData.name"
        showProductGroups
        showStartDate
        :startDate="formData.priceStartDate | formatedDate"
        showEndDate
        :endDate="formData.priceEndDate | formatedDate"
        moduleSlug="flat-flyer-pricing"
        :nameLabel="$t(`flat-flyer-pricing.name-label`)"
        :siteLabel="$t(`flat-flyer-pricing.selected_site-label`)"
        :chooseProductLabel="$t(`flat-flyer-pricing.choose_product-label`)"
        :startDateLabel="$t(`flat-flyer-pricing.start_date-label`)"
        :endDateLabel="$t(`flat-flyer-pricing.end_date-label`)"
        v-if="showFormPreview"
      />

      <div class="d-flex flex-row-reverse">
        <b-button
          id="find-products-btn"
          @click="handleFindProduct"
          v-if="showForm"
          variant="brand-primary"
          >{{ $t(`flat-flyer-pricing.find_product-btn-label`) }}
        </b-button>
        <b-button
          id="edit-products-grid-btn"
          @click="editProductGrid"
          v-if="
            showFormPreview &&
            isGridEditable == false &&
            showFilteredProducts == true
          "
          variant="brand-secondary"
          >{{ $t(`flat-flyer-pricing.edit_price-btn-label`) }}
        </b-button>

        <b-button
          id="validate-btn"
          @click="handleValidateProduct"
          v-if="
            showFormPreview &&
            isGridEditable == true &&
            showFilteredProducts == true
          "
          variant="brand-primary"
          class="mr-3"
          >{{ $t(`flat-flyer-pricing.validate-btn-label`) }}</b-button
        >

        <b-button
          id="submit-btn"
          @click="submitForm"
          v-if="
            showFormPreview &&
            isGridEditable == false &&
            showFilteredProducts == false &&
            showValidatedProducts == true
          "
          variant="brand-primary"
          >{{ $t(`flat-flyer-pricing.submit-btn-label`) }}</b-button
        >

        <b-button
          id="edit-btn"
          @click="handleEdit"
          v-if="showFormPreview"
          variant="brand-secondary"
          class="mr-3"
          >{{ $t(`flat-flyer-pricing.edit_find-btn-label`) }}</b-button
        >
      </div>
    </b-card>
    <b-alert
      :show="editColumnDismissCountDown"
      variant="primary"
      dismissible
      class="mt-4"
      @dismissed="editColumnDismissCountDown = 0"
      @dismiss-count-down="countDownChangedEditColumn"
    >
      <i class="icon-info mr-1"></i>
      {{ $t(`flat-flyer-pricing.edittable_column-msg`) }}
    </b-alert>
    <b-alert
      :show="invalidProdcutsDismissCountDown"
      variant="danger"
      dismissible
      class="mt-4"
      @dismissed="invalidProdcutsDismissCountDown = 0"
      @dismiss-count-down="countDownChangedInvalidProdcuts"
    >
      <i class="icon-error mr-1"></i>
      {{ $t(`flat-flyer-pricing.invalid_data-error-msg`) }}
    </b-alert>
    <b-card
      v-if="
        productPreviewFailed || showFilteredProducts || showValidatedProducts
      "
      class="mt-4"
    >
      <product-preview-failed v-if="productPreviewFailed" />
      <product-editable-grid
        v-if="showFilteredProducts"
        formName="flat-flyer-pricing"
        :isGridEditable="isGridEditable"
        @filteredProducts="setFilteredProducts"
        :fileTitle="formData.name"
        moduleSlug="flat-flyer-pricing"
      />
      <product-preview
        v-if="showValidatedProducts"
        formName="flat-flyer-pricing"
        :fileTitle="formData.name"
        moduleSlug="flat-flyer-pricing"
      />
    </b-card>
    <b-modal
      dialog-class="success-modal"
      centered
      hide-header
      hide-footer
      ref="success-modal"
    >
      <div
        class="d-flex justify-content-center flex-column align-items-center text-center"
      >
        <i class="font-50 icon-completed mb-2"></i>
        <div class="message-detail">
          <h5 class="msg-title">
            {{ $t(`flat-flyer-pricing.success_message-title`) }}
          </h5>
          <i18n
            :path="`flat-flyer-pricing.success_message-desc`"
            tag="p"
            class="msg-desc m-0"
          >
            <b-link to="/pricing-history">{{
              $t(`flat-flyer-pricing.success_message-desc_term`)
            }}</b-link>
          </i18n>
        </div>
      </div>
    </b-modal>
  </div>
  <div v-else>
    <b-card class="mt-4">
      <form-success>
        <h5 class="msg-title">
          {{ $t(`flat-flyer-pricing.success_message-title`) }}
        </h5>
        <i18n
          :path="`flyer-pricing-import.success_message-desc`"
          tag="p"
          class="msg-desc m-0"
        >
          <b-link to="/pricing-history">{{
            $t(`flyer-pricing-import.success_message-desc_term`)
          }}</b-link>
        </i18n>
      </form-success>
    </b-card>
  </div>
</template>

<script>
import { eventBus } from "../../../main";
import config from "../../../../config";
import ProductFilters from "../../../components/ProductFilters.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { forEach, map, max, isObject, trim, clone, isEmpty } from "lodash";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import PreviewForm from "../../../components/PreviewForm.vue";
import ProductPreviewFailed from "../../../components/ProductPreviewFailed.vue";
import ProductPreview from "../../../components/ProductPreview.vue";
import ProductEditableGrid from "../../../components/ProductEditableGrid.vue";
import FormSuccess from "../../../components/FormSuccess.vue";

const {
  formPriority,
  enableProductGroupBoxStyling,
  enableAddProductGroup,
  dateFormat,
  discountType,
  pricingManagerConfig
} = config;

const isValidProductGroup = (productGroup) => {
  let isValid = false;
  if (
    productGroup.vendor.length != 0 ||
    productGroup.category.length != 0 ||
    productGroup.productTags.length != 0 ||
    productGroup.productVendorModel.length != 0 ||
    productGroup.productSKU.length != 0
  ) {
    isValid = true;
  }

  return isValid;
};

const isInvalidProductGroup = (value) => {
  let nonValidatedProductGroups = [];
  let validateStatus = [];
  forEach(value, (productGroup, index) => {
    let isValid = isValidProductGroup(productGroup);
    validateStatus.push(isValid);
    if (!isValid) nonValidatedProductGroups.push(index + 1);
  });

  return validateStatus.includes(false);
};

export default {
  name: "FlatFlyerPricing",
  metaInfo() {
    return {
      title: `${this.$t("general.flat-flyer-pricing")} | ${this.$t(
        "general.flyer-pricing"
      )} | ${this.$t("general.pricing-manager")}`,
    };
  },
  components: {
    ProductFilters,
    PreviewForm,
    ProductPreviewFailed,
    ProductPreview,
    ProductEditableGrid,
    FormSuccess,
  },
  data() {
    return {
      isPushPricing: pricingManagerConfig.isPushPricing,
      priority: formPriority["flat-flyer-pricing"],
      enableProductGroupBoxStyling: enableProductGroupBoxStyling,
      enableAddProductGroup: enableAddProductGroup,
      showForm: true,
      showFormPreview: false,
      showFilteredProducts: false,
      productPreviewFailed: false,
      isFormSubmitted: false,
      isGridEditable: false,
      requiredFilters: [
        "vendorFilter",
        "categoryFilter",
        "productTagFilter",
        "vendorModelFilter",
        "skuFilter",
      ],
      formData: {
        id: 0,
        name: "",
        siteId: "",
        priceStartDate: new Date(),
        priceEndDate: "",
        marginFactor: 0,
        priceEndings: 0,
        discount: 0,
        priceTypeId: formPriority["flat-flyer-pricing"],
        discountType: discountType.flatOff,
      },
      dateFormat: dateFormat,
      filteredProducts: [],
      showEditProductAlert: false,
      isInvalidProducts: false,
      showValidatedProducts: false,
      masks: {
        input: dateFormat,
      },
      modelConfig: {
        type: "string",
        mask: "iso",
      },
      //added auto hide count for alerts.
      dismissSecs: 2,
      editColumnDismissCountDown: 0,
      invalidProdcutsDismissCountDown: 0,
    };
  },
  computed: {
    ...mapGetters([
      "productGroups",
      "filteredProductError",
      "selectedSite",
      "selected_language",
    ]),
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("pricingManager", [
      "pricingManagerId",
      "mode",
      "preFilledData",
    ]),
    sitesList() {
      let sites = [];
      forEach(this.siteAccess, (site) => {
        let obj = { text: site.Name, value: site.SiteId };
        sites.push(obj);
      });
      return sites;
    },
    availableProductGroups() {
      let group = [];
      forEach(this.productGroups, (productGroup, index) => {
        group.push(index + 1);
      });
      return group.join();
    },
    controllerName() {
      return "FlatFlyerPricing.vue";
    },
    moduleName() {
      return "Flat Flyer Pricing";
    },
  },
  mounted() {
    this.resetForm();
    let preFilledData = this.preFilledData;
    if (
      preFilledData &&
      isObject(preFilledData) &&
      Object.keys(preFilledData).length > 0
    ) {
      this.formData.id = this.mode == "edit" ? preFilledData.Id : 0;
      this.formData.name =
        this.mode == "edit"
          ? preFilledData.Name
          : "Copy of " + preFilledData.Name;
      this.formData.siteId = preFilledData.SiteId;
      this.formData.priceStartDate = new Date(preFilledData.PriceStartDate);

      if (
        preFilledData.PriceEndDate == "2099-12-31T00:00:00" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00Z" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00.000Z"
      ) {
        this.formData.priceEndDate = "";
      } else {
        this.formData.priceEndDate = new Date(preFilledData.PriceEndDate);
      }

      let productcondition = preFilledData.productcondition;

      let _productGroups = [];

      let siteId = this.formData.siteId;

      let proccessData = productcondition.reduce(function (results, item) {
        let loopIndex = "key_" + item.ProductGroupId;

        results[loopIndex] = results[loopIndex] || [];

        results[loopIndex].vendor = results[loopIndex].vendor || [];
        results[loopIndex].category = results[loopIndex].category || [];
        results[loopIndex].productTags = results[loopIndex].productTags || [];
        results[loopIndex].productVendorModel =
          results[loopIndex].productVendorModel || [];
        results[loopIndex].productSKU = results[loopIndex].productSKU || [];

        if (item.ConditionType == "Vendor") {
          let vendorObj = {
            condition: trim(item.Condition),
            siteId: siteId,
            vendorId: trim(item.ConditionId),
            vendorName: trim(item.ConditionValue),
          };

          results[loopIndex].vendor.push(vendorObj);
        } else if (item.ConditionType == "Category") {
          let cateObj = {
            roomGroupingId: trim(item.ConditionId),
            roomGrouping: trim(item.ConditionValue),
            condition: trim(item.Condition),
            siteId: siteId,
            storeBrandId: item.storeBrandId ? trim(item.storeBrandId) : null,
          };

          results[loopIndex].category.push(cateObj);
        } else if (item.ConditionType == "ProductTag") {
          let tagObj = {
            id: trim(item.ConditionId),
            tag: trim(item.ConditionValue),
            condition: trim(item.Condition),
            siteId: this.formData.siteId,
          };

          results[loopIndex].productTags.push(tagObj);
        } else if (item.ConditionType == "VendorModelNumber") {
          let vmnObj = {
            productVendorModelId: trim(item.ConditionId),
            productVendorModel: trim(item.ConditionValue),
            condition: trim(item.Condition),
          };

          results[loopIndex].productVendorModel.push(vmnObj);
        } else if (item.ConditionType == "ProductSKU") {
          let skuObj = {
            productSKUId: trim(item.ConditionId),
            productSKU: trim(item.ConditionValue),
            condition: trim(item.Condition),
          };

          results[loopIndex].productSKU.push(skuObj);
        }
        return results;
      }, {});

      let _productGroupObject = {
        groupId: "1",
        vendor: [],
        category: [],
        productTags: [],
        productVendorModel: [],
        productSKU: [],
      };

      for (const key in proccessData) {
        let groupId = key.replace("key_", "");

        let obj = clone(_productGroupObject);
        obj.groupId = groupId;
        obj.vendor = proccessData[key]["vendor"];
        obj.category = proccessData[key]["category"];
        obj.productTags = proccessData[key]["productTags"];
        obj.productVendorModel = proccessData[key]["productVendorModel"];
        obj.productSKU = proccessData[key]["productSKU"];

        _productGroups.push(obj);

        setTimeout(() => {
          eventBus.$emit("setVendorFilter", {
            id: groupId,
            vendor: obj.vendor,
          });
          eventBus.$emit("setCategoryFilter", {
            id: groupId,
            category: obj.category,
          });
          eventBus.$emit("setTagFilter", { id: groupId, tag: obj.productTags });
          eventBus.$emit("setVmnFilter", {
            id: groupId,
            vmn: obj.productVendorModel,
          });
          eventBus.$emit("setSkuFilter", { id: groupId, sku: obj.productSKU });
        }, 900);
      }
      setTimeout(() => {
        this.setProductGroups({ type: "update", data: _productGroups });
      }, 700);
    }
  },
  beforeDestroy() {
    this.resetForm();
    this.pricingSetItem({ resource: "pricingManagerId", payload: 0 });
    this.pricingSetItem({ resource: "mode", payload: "" });
    this.pricingSetItem({ resource: "preFilledData", payload: null });
  },
  methods: {
    ...mapActions([
      "addNewProductGroup",
      "setProductGroups",
      "resetFormState",
      "getPricingFilteredProducts",
    ]),
    ...mapActions("pricingManager", [
      "submitPricingForm",
      "validateFlatFlyerProducts",
    ]),
    ...mapMutations(["setItem"]),
    ...mapMutations("pricingManager", {
      pricingSetItem: "setItem",
    }),
    createNewProductGroup() {
      let groupIds = map(this.productGroups, (_data) => Number(_data.groupId));
      let lastGroupId = max(groupIds);
      this.addNewProductGroup(lastGroupId + 1);
    },
    removeProductGroup(index) {
      let _productGroup = this.productGroups;
      _productGroup.splice(index, 1);
      this.setProductGroups({ type: "update", data: _productGroup });
    },
    showSuccessModal() {
      this.$refs["success-modal"].show();
    },
    hideSuccessModal() {
      this.$refs["success-modal"].hide();
    },
    async handleFindProduct() {
      this.$v.formData.$touch();
      this.$v.productGroups.$touch();
      this.productPreviewFailed = false;
      if (
        this.$v.formData.$invalid ||
        this.$v.productGroups.isInvalidProductGroup
      ) {
        return;
      } else {
        let formData = Object.assign({}, this.formData);

        let _priceEndDate = "2099-12-31T00:00:00Z";

        formData.userId = this.loggedUserId;
        formData.productGroups = this.productGroups;
        formData.priceStartDate = new Date(
          formData.priceStartDate
        ).toISOString();
        formData.priceEndDate =
          formData.priceEndDate == "" || formData.priceEndDate == null
            ? _priceEndDate
            : new Date(formData.priceEndDate).toISOString();

        formData.exceptionParams = {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        };

        await this.getPricingFilteredProducts(formData);

        if (this.filteredProductError == false) {
          this.showForm = false;
          this.showFilteredProducts = this.showFormPreview = true;
        } else {
          this.productPreviewFailed = true;
        }
      }
    },
    handleEdit() {
      this.setItem({ resource: "filteredProductError", payload: false });
      this.pricingSetItem({ resource: "validFlatFlyerData", payload: [] });
      this.pricingSetItem({ resource: "errorFlatFlyerData", payload: null });
      if (
        this.formData.priceEndDate == "2099-12-31T00:00:00" ||
        this.formData.priceEndDate == "2099-12-31T00:00:00Z" ||
        this.formData.priceEndDate == "2099-12-31T00:00:00.000Z"
      ) {
        this.formData.priceEndDate = "";
      }
      this.showForm = true;
      this.showFormPreview =
        this.showFilteredProducts =
        this.productPreviewFailed =
        this.isGridEditable =
        this.showEditProductAlert =
        this.isInvalidProducts =
        this.showValidatedProducts =
          false;

      this.editColumnDismissCountDown = 0;
      this.invalidProdcutsDismissCountDown = 0;
    },
    resetForm() {
      this.resetFormState();
      this.handleEdit();
      this.$v.$reset();

      this.formData.id = 0;
      this.formData.name = "";
      this.formData.priceStartDate = new Date();
      this.formData.priceEndDate = "";
      this.formData.marginFactor = 0;
      this.formData.priceEndings = 0;
      this.formData.discount = 0;
      this.formData.discountType = discountType.flatOff;
    },
    async submitForm() {
      this.setItem({ resource: "isLoading", payload: true });
      this.submitPricingForm({
        id: this.pricingManagerId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then((response) => {
          this.setItem({ resource: "isLoading", payload: false });
          if (response.status == 1) {
            // this.isFormSubmitted = true;
            this.showSuccessModal();
            this.formData.siteId = "";
          }
        })
        .catch(() => {
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
    editProductGrid() {
      this.isGridEditable = this.showEditProductAlert = true;
      this.editColumnDismissCountDown = this.dismissSecs;
    },
    setFilteredProducts(products) {
      this.filteredProducts = products;
    },
    async handleValidateProduct() {
      this.isInvalidProducts = false;

      let invalidData = this.filteredProducts.filter((product) => {
        if (parseFloat(product.flyerPrice) > parseFloat(product.regularPrice)) {
          return true;
        }
      });

      let nullPriceData = this.filteredProducts.filter((product) => {
        if (
          parseFloat(product.flyerPrice) == 0 ||
          parseFloat(product.regularPrice) == 0
        ) {
          return true;
        }
      });

      if (invalidData.length > 0 || nullPriceData.length > 0) {
        this.isInvalidProducts = true;
        this.invalidProdcutsDismissCountDown = this.dismissSecs;
      }

      if (!this.isInvalidProducts) {
        this.setItem({ resource: "isLoading", payload: true });

        this.validateFlatFlyerProducts({
          pricingManagerId: this.pricingManagerId,
          data: this.filteredProducts,
          exceptionParams: {
            userId: this.loggedUserId,
            controllerName: this.controllerName,
            moduleName: this.moduleName,
          },
        })
          .then((response) => {
            if (response == 1) {
              this.showFilteredProducts =
                this.isGridEditable =
                this.showEditProductAlert =
                  false;
              this.showValidatedProducts = true;
              this.editColumnDismissCountDown = 0;
            }
            this.setItem({ resource: "isLoading", payload: false });
          })
          .catch(() => {
            this.setItem({ resource: "isLoading", payload: false });
          });
      }
    },
    showStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
        this.$refs.priceStartDate.togglePopover();
      });
    },
    showEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
        this.$refs.priceEndDate.togglePopover();
      });
    },
    hideStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
      });
    },
    hideEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
      });
    },
    countDownChangedEditColumn(editColumnDismissCountDown) {
      this.editColumnDismissCountDown = editColumnDismissCountDown;
    },
    countDownChangedInvalidProdcuts(invalidProdcutsDismissCountDown) {
      this.invalidProdcutsDismissCountDown = invalidProdcutsDismissCountDown;
    },
  },
  watch: {
    "formData.siteId": function (newVal, oldVal) {
      let selectedSite = this.siteAccess.find((site) => site.SiteId == newVal);

      if (oldVal != "") this.resetForm();
      if (!selectedSite) return;

      this.setItem({ resource: "selectedSite", payload: selectedSite });
      eventBus.$emit("resetFilters");
    },
    selected_language() {
      this.$v.$reset();
    },
  },
  validations: {
    formData: {
      name: { required },
      siteId: { required },
      priceStartDate: {
        required,
        minValue(value) {
          let sdate = moment(value);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = "";

          if (!isEmpty(this.formData.priceEndDate)) {
            edate = moment(this.formData.priceEndDate);
            edate.set("hour", 0);
            edate.set("minute", 0);
            edate.set("second", 0);
            edate.set("millisecond", 0);

            if (
              moment(edate)._i == "2099-12-31T00:00:00" ||
              moment(edate)._i == "2099-12-31T00:00:00Z" ||
              moment(edate)._i == "2099-12-31T00:00:00.000Z"
            ) {
              edate = "";
            }
          }

          let status = this.checkStartDateValidation(sdate, edate, this.mode);

          return status;
        },
      },
      priceEndDate: {
        minValue(value) {
          let sdate = moment(this.formData.priceStartDate);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = moment(value);
          edate.set("hour", 0);
          edate.set("minute", 0);
          edate.set("second", 0);
          edate.set("millisecond", 0);

          if (
            moment(edate)._i == "2099-12-31T00:00:00" ||
            moment(edate)._i == "2099-12-31T00:00:00Z" ||
            moment(edate)._i == "2099-12-31T00:00:00.000Z"
          ) {
            edate = "";
          }

          let status = this.checkEndDateValidation(sdate, edate);

          return status;
        },
      },
    },
    productGroups: {
      isInvalidProductGroup,
    },
  },
};
</script>
