<template>
  <div class="product-preview">
    <!-- <table-pagination
      :fromLimit="fromLimit | addLeadingZero"
      :toLimit="toLimit | addLeadingZero"
      :totalRecords="totalRecords | addLeadingZero"
      :showExportButton="exportData"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @paginate="paginate"
      @downloadData="download"
      :recordsTitle="recordsTitle"
      :countInfo="`${moduleSlug}.table_count-info`"
      class="mb-3"
    /> -->
    <div class="d-flex flex-row-reverse mb-3">
      <b-link
        class="export-csv my-md-0 my-2"
        v-if="exportData"
        :disabled="totalRecords == '00'"
        @click="download"
        >{{ $t(`general.export-csv`) }}
        <i class="icon-download ml-1"></i>
      </b-link>
    </div>
    <b-table
      hover
      responsive
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      tbody-tr-class="text-nowrap"
      show-empty
      :empty-text="$t('general.no-data-msg')"
    >
      <template #head(products)>
        {{ $t(`${moduleSlug}.table_head_products`) }}
      </template>
      <template #head(pricingManagerId)>
        {{ $t(`${moduleSlug}.table_head_pricingManagerId`) }}
      </template>
      <template #head(siteName)>
        {{ $t(`${moduleSlug}.table_head_siteName`) }}
      </template>
      <template #head(sku)>
        {{ $t(`${moduleSlug}.table_head_sku`) }}
      </template>
      <template #head(vmn)>
        {{ $t(`${moduleSlug}.table_head_vmn`) }}
      </template>
      <template #head(everydayCost)>
        {{ $t(`${moduleSlug}.table_head_everydayCost`) }}
      </template>
      <template #head(regularPrice)>
        {{ $t(`${moduleSlug}.table_head_regularPrice`) }}
      </template>
      <template #head(promoPrice)>
        {{ $t(`${moduleSlug}.table_head_promoPrice`) }}
      </template>
      <template #head(replacementCost)="data">
        {{ data.label }}
      </template>
      <template #head(flyerImportCost)>
        {{ $t(`${moduleSlug}.table_head_flyerImportCost`) }}
      </template>
      <template #head(flyerImportPrice)>
        {{ $t(`${moduleSlug}.table_head_flyerImportPrice`) }}
      </template>
      <template #head(flatFlyerRegularPrice)>
        {{ $t(`${moduleSlug}.table_head_flatFlyerRegularPrice`) }}
        <i class="icon-tooltip ml-1" id="comparedatPrice-tootltip"></i>
        <b-tooltip target="comparedatPrice-tootltip"
          ><p
            v-html="
              $t(`${moduleSlug}.table_head_flatFlyerRegularPrice-tooltip`)
            "
        /></b-tooltip>
      </template>
      <template #head(flatFlyerPrice)>
        {{ $t(`${moduleSlug}.table_head_flatFlyerPrice`) }}
        <i class="icon-tooltip ml-1" id="flatFlyerPrice-tootltip"></i>
        <b-tooltip target="flatFlyerPrice-tootltip"
          ><p v-html="$t(`${moduleSlug}.table_head_flatFlyerPrice-tooltip`)"
        /></b-tooltip>
      </template>

      <template #cell(products)="data">
        <b-img
          src="/media/icons/rectangle.svg"
          alt="Product Rectangle Icon"
          v-if="showProductIcon"
        />
        {{ data.item.productName }}
        <!-- <i
          class="icon-tooltip ml-1"
          v-b-tooltip.hover="data.item.pricingManagerId"
          role="button"
        ></i> -->
      </template>

      <template #cell(pricingManagerId)="data">
        {{ data.item.pricingManagerId }}
      </template>

      <template #cell(sku)="data">
        {{ data.item.productId }}
      </template>

      <template #cell(vmn)="data">
        {{ data.item.vendorModelNumber }}
      </template>

      <template #cell(everydayCost)="data">
        {{ data.item.replacementCost }}
      </template>

      <template #cell(regularPrice)="data">
        {{ data.item.regularPrice }}
      </template>

      <template #cell(promoPrice)="data">
        {{ data.item.promoPrice }}
      </template>

      <template #cell(flatFlyerRegularPrice)="data">
        <template v-if="!isGridEditable">
          {{ data.item.regularPrice }}
        </template>
        <template v-else>
          <div class="d-flex">
            <div
              v-if="data.item.editRegularPrice == false"
              @dblclick="data.item.editRegularPrice = true"
              role="button"
            >
              {{ data.item.regularPrice }}
              <i
                class="icon-success ml-1"
                v-show="data.item.isRegularPriceUpdated"
              ></i>
            </div>
            <div v-else>
              <input
                class="form-control"
                :id="'flatFlyerRegularPrice_' + data.item.productId"
                :value="data.item.regularPrice"
                @keyup.esc="data.item.editRegularPrice = false"
                v-clickoutside="onClickOutside"
                @keyup.enter="
                  data.item.editRegularPrice = false;
                  updateFlatFlyerRegularPrice(data.item, $event);
                "
              />
            </div>
          </div>
        </template>
      </template>

      <template #cell(flatFlyerPrice)="data">
        <template v-if="!isGridEditable">
          {{ data.item.flyerPrice }}
        </template>
        <template v-else>
          <div class="d-flex">
            <div
              v-if="data.item.editFlyerPrice == false"
              @dblclick="data.item.editFlyerPrice = true"
              role="button"
            >
              {{ data.item.flyerPrice }}
              <i
                class="icon-success ml-1"
                v-show="data.item.isFlyerPriceUpdated"
              ></i>
            </div>
            <div v-else>
              <input
                class="form-control"
                :id="'flatFlyerPrice_' + data.item.productId"
                :value="data.item.flyerPrice"
                @keyup.esc="data.item.editFlyerPrice = false"
                v-clickoutside="onClickOutside"
                @keyup.enter="
                  data.item.editFlyerPrice = false;
                  updateFlatFlyerPrice(data.item, $event);
                "
              />
            </div>
          </div>
        </template>
      </template>
    </b-table>
    <table-pagination
      class="mt-3"
      :fromLimit="fromLimit | addLeadingZero"
      :toLimit="toLimit | addLeadingZero"
      :totalRecords="totalRecords | addLeadingZero"
      :currentPage="currentPage"
      :totalPages="totalPages"
      @paginate="paginate"
      :countInfo="`${moduleSlug}.table_count-info`"
      :recordsTitle="recordsTitle"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import config from "../../config";
import { forEach, ceil } from "lodash";
import moment from "moment";
import TablePagination from "./TablePagination.vue";

const { tableHeaders, exportCSVHeaders } = config;

export default {
  components: { TablePagination },
  name: "ProductEditableGrid",
  props: {
    formName: {
      type: String,
      default: "everyday-pricing",
    },
    showProductIcon: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: [String, Array],
      default: "20",
    },
    exportData: {
      type: Boolean,
      default: true,
    },
    isGridEditable: {
      type: Boolean,
      default: false,
    },
    fileTitle: {
      type: String,
      default: "data",
    },
    recordsTitle: {
      type: String,
      default: "products",
    },
    moduleSlug: {
      type: String,
      default: "everyday-pricing",
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(["filteredProducts"]),
    ...mapGetters("pricingManager", ["pricingManagerId"]),
    ...mapGetters("pricingManager", ["history"]),
    fields() {
      return tableHeaders[this.formName];
    },
    productdata() {
      if (this.formName == "flat-flyer-pricing") {
        let priducts = this.filteredProducts.map((item) => {
          return {
            ...item,
            editRegularPrice: false,
            editFlyerPrice: false,
            isRegularPriceUpdated: false,
            isFlyerPriceUpdated: false,
          };
        });

        this.$emit("filteredProducts", priducts);

        return priducts;
      } else {
        return this.filteredProducts;
      }
    },
    items() {
      return this.productdata;
    },
    totalRecords() {
      return this.productdata.length;
    },
    totalPages() {
      return ceil(this.productdata.length / this.perPage);
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
    csvFields() {
      return exportCSVHeaders[this.formName];
    },
    csvHeader() {
      let header = {};
      forEach(this.csvFields, (field) => {
        header[field.key] = field.label;
      });
      return header;
    },
  },
  watch: {
    productdata() {
      this.currentPage = 1;
    },
  },
  methods: {
    ...mapActions("pricingManager", {
      pricingExportPreviewData: "getExportPreviewData",
      pricingHistory: "getPricingHistory",
    }),
    ...mapMutations("pricingManager", {
      pricingSetItem: "setItem",
    }),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    prepareCSVdata(headers, productdata) {
      let csvData = [];
      forEach(productdata, (data) => {
        let _csvData = {};
        forEach(headers, (val, key) => {
          _csvData[key] = "";

          if (key == "pricingManagerId") {
            _csvData[key] = String(this.pricingManagerId).replace(/,/g, ""); // remove commas to avoid errors,
          }

          if (key == "pricing") {
            if ("pricing" in data) {
              let formList = [
                "everyday-pricing",
                "promotional-pricing",
                "active-promotion",
                "flyer-pricing",
                "flat-flyer-pricing",
              ];
              if (formList.includes(this.formName)) {
                _csvData[key] = String(data.pricing).replace(/,/g, ""); // remove commas to avoid errors,
              }
            }
          }

          if (key == "productId") {
            if ("productId" in data) {
              _csvData[key] = String(data.productId).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "products") {
            _csvData[key] = String(data.productName).replace(/,/g, ""); // remove commas to avoid errors,
          }

          if (key == "vmn") {
            if ("vendorModelNumber" in data) {
              _csvData[key] = String(data.vendorModelNumber).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "sku") {
            if ("productSKU" in data) {
              _csvData[key] = String(data.productSKU).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "regularPrice") {
            if ("regularPrice" in data) {
              _csvData[key] = String(data.regularPrice).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "promoPrice") {
            if ("promoPrice" in data) {
              _csvData[key] = String(data.promoPrice).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "everydayCost") {
            if ("replacementCost" in data) {
              _csvData[key] = String(data.replacementCost).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "replacementCost") {
            if ("replacementCost" in data) {
              _csvData[key] = String(data.replacementCost).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "vendor") {
            if ("vendor" in data) {
              _csvData[key] = String(data.vendor).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "category") {
            if ("category" in data) {
              _csvData[key] = String(data.category).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "productTags") {
            if ("productTag" in data) {
              _csvData[key] = String(data.productTag).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "siteId") {
            if ("siteId" in data) {
              _csvData[key] = String(data.siteId).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "siteName") {
            if ("siteName" in data) {
              _csvData[key] = String(data.siteName).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "flyerPrice") {
            if ("flyerPrice" in data) {
              _csvData[key] = String(data.flyerPrice).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
        });
        csvData.push(_csvData);
      });
      return csvData;
    },
    async download() {
      try {
        this.generalSetItem({ resource: "isLoading", payload: true });
        let headers = this.csvHeader;
        let productdata = this.productdata;
        if (this.formName == "flat-flyer-pricing") {
          productdata = await this.pricingExportPreviewData({
            pricingManagerId: this.pricingManagerId,
            exceptionParams: {
              userId: this.loggedUserId,
              controllerName: "FlatFlyerPricing.vue",
              moduleName: "Flat Flyer Pricing",
            },
          });
        }
        let csvData = this.prepareCSVdata(headers, productdata);

        let fileTitle =
          this.fileTitle + "_" + moment(new Date()).format("YYYY/MM/DD");

        this.generalSetItem({ resource: "isLoading", payload: false });

        this.exportCSVFile(headers, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
      } catch {
        this.generalSetItem({ resource: "isLoading", payload: false });
      }
    },
    updateFlatFlyerRegularPrice(item, $event) {
      let index = this.items.findIndex((el) => el.productId == item.productId);
      if (this.items[index].regularPrice != $event.target.value) {
        this.items[index].isRegularPriceUpdated = true;
        this.items[index].regularPrice = $event.target.value;
      }

      this.$emit("filteredProducts", this.items);
    },
    updateFlatFlyerPrice(item, $event) {
      let index = this.items.findIndex((el) => el.productId == item.productId);
      if (this.items[index].flyerPrice != $event.target.value) {
        this.items[index].isFlyerPriceUpdated = true;
        this.items[index].flyerPrice = $event.target.value;
      }
      this.$emit("filteredProducts", this.items);
    },
    onClickOutside() {
      let regularPriceIndex = this.items.findIndex(
        (el) => el.editRegularPrice == true
      );
      let flyerPriceIndex = this.items.findIndex(
        (el) => el.editFlyerPrice == true
      );

      if (regularPriceIndex != -1) {
        let regularPrice = document.getElementById(
          `flatFlyerRegularPrice_${this.items[regularPriceIndex].productId}`
        ).value;
        if (this.items[regularPriceIndex].regularPrice != regularPrice) {
          this.items[regularPriceIndex].isRegularPriceUpdated = true;
          this.items[regularPriceIndex].regularPrice = regularPrice;
        }
        this.items[regularPriceIndex].editRegularPrice = false;
      }

      if (flyerPriceIndex != -1) {
        let flatFlyerPrice = document.getElementById(
          `flatFlyerPrice_${this.items[flyerPriceIndex].productId}`
        ).value;
        if (this.items[flyerPriceIndex].flyerPrice != flatFlyerPrice) {
          this.items[flyerPriceIndex].isFlyerPriceUpdated = true;
          this.items[flyerPriceIndex].flyerPrice = flatFlyerPrice;
        }
        this.items[flyerPriceIndex].editFlyerPrice = false;
      }

      this.$emit("filteredProducts", this.items);
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
  },
};
</script>
