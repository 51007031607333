var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isFormSubmitted)?_c('div',[_c('b-card',[(_vm.showForm)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('b-form-group',{class:{ 'form-group--error': _vm.$v.formData.name.$error },attrs:{"id":"name","label":_vm.$t(`flat-flyer-pricing.name-label`),"label-for":"name","label-class":['form-label', 'required']}},[_c('b-form-input',{class:{ 'is-invalid': _vm.$v.formData.name.$error },attrs:{"id":"name","placeholder":_vm.$t(`flat-flyer-pricing.name-placeholder`),"trim":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"name-feedback"}},[_vm._v(" "+_vm._s(_vm.$t(`flat-flyer-pricing.name-required-error-msg`))+" ")])],1)],1),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('b-form-group',{class:{ 'form-group--error': _vm.$v.formData.name.$error },attrs:{"id":"siteId","label":_vm.$t(`flat-flyer-pricing.select_site-label`),"label-for":"siteId","label-class":['form-label', 'required']}},[_c('b-dropdown',{ref:"dropdown",class:{ 'is-invalid': _vm.$v.formData.siteId.$error },attrs:{"id":"dropdown-form","text":_vm.formData.siteId == ''
                  ? _vm.$t(`flat-flyer-pricing.select_site-placeholder`)
                  : _vm.selectedSite.Name,"block":"","menu-class":"w-100 scrollable","no-caret":"","lazy":"","variant":"brand-dropdown","toggle-class":{ 'is-invalid': _vm.$v.formData.siteId.$error }}},_vm._l((_vm.sitesList),function(site,index){return _c('b-dropdown-item',{key:index,attrs:{"active":_vm.formData.siteId == site.value},on:{"click":function($event){_vm.formData.siteId = site.value}}},[_vm._v(_vm._s(site.text))])}),1),_c('b-form-invalid-feedback',{attrs:{"id":"siteId-feedback"}},[_vm._v(" "+_vm._s(_vm.$t(`flat-flyer-pricing.select_site-required-error-msg`))+" ")])],1)],1)]),_c('div',{staticClass:"mb-4",attrs:{"role":"group"}},[_c('label',{staticClass:"form-label required",attrs:{"for":"chooseProducts"}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.choose_product-label`)))]),_c('i',{staticClass:"icon-tooltip ml-1",attrs:{"id":"chooseProducts-tootltip"}}),_c('b-tooltip',{attrs:{"target":"chooseProducts-tootltip"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(`flat-flyer-pricing.choose_product-tooltip`))}})]),_c('div',{staticClass:"product-filters",attrs:{"id":"productFilters"}},[_vm._l((_vm.productGroups),function(productGroup,index){return _c('product-filters',{key:index,class:{
              'single-filter':
                !_vm.enableProductGroupBoxStyling && !_vm.enableAddProductGroup,
              'is-invalid':
                _vm.formData.siteId != '' &&
                _vm.$v.productGroups.isInvalidProductGroup &&
                _vm.$v.productGroups.$dirty,
            },attrs:{"filterLabel":"Choose products","id":productGroup.groupId,"index":index,"totalGroups":_vm.productGroups.length,"requiredFilters":_vm.requiredFilters,"disabled":_vm.formData.siteId == '',"moduleSlug":"flat-flyer-pricing","controllerName":_vm.controllerName,"moduleName":_vm.moduleName,"isPushPricing":_vm.isPushPricing},on:{"removeProductGroup":_vm.removeProductGroup}})}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(!_vm.enableProductGroupBoxStyling && !_vm.enableAddProductGroup ? _vm.sytemMessages.productGroupRequired : _vm.sytemMessages.productGroupsRequired + _vm.availableProductGroups)+" ")])],2),(_vm.enableProductGroupBoxStyling && _vm.enableAddProductGroup)?_c('b-button',{attrs:{"variant":"link","disabled":_vm.formData.siteId == ''},on:{"click":_vm.createNewProductGroup}},[_vm._v("Add New Product-Group")]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"mb-4",attrs:{"id":"startDate","role":"group"}},[_c('label',{staticClass:"form-label required",attrs:{"for":"startDate"}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.start_date-label`)))]),_c('div',{staticClass:"flex w-full",class:{ 'is-invalid': _vm.$v.formData.priceStartDate.$error }},[_c('v-date-picker',{ref:"priceStartDate",staticClass:"flex-grow",class:{ 'is-invalid': _vm.$v.formData.priceStartDate.$error },attrs:{"locale":_vm.selected_language,"masks":_vm.masks,"is-required":"","popover":{ visibility: 'click' },"model-config":_vm.modelConfig,"disabled-dates":[
                  {
                    start: null,
                    end: _vm.yesterday,
                  },
                ],"available-dates":{
                  start: new Date(_vm.formData.priceStartDate),
                  end: new Date(_vm.formData.priceStartDate),
                }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue }){return [_c('div',{staticClass:"d-flex custom-datepicker"},[_c('input',{staticClass:"form-control bg-white rounded-top-right-0 rounded-bottom-right-0",class:{
                        'is-invalid': _vm.$v.formData.priceStartDate.$error,
                      },attrs:{"placeholder":_vm.dateFormat,"readonly":""},domProps:{"value":inputValue},on:{"click":_vm.showStartDatePicker}}),_c('div',{staticClass:"picker-icon",class:{
                        'is-invalid': _vm.$v.formData.priceStartDate.$error,
                      },attrs:{"slot":"afterDateInput","role":"button"},on:{"click":_vm.showStartDatePicker},slot:"afterDateInput"},[_c('i',{staticClass:"icon-date"})])])]}}],null,false,1263254367),model:{value:(_vm.formData.priceStartDate),callback:function ($$v) {_vm.$set(_vm.formData, "priceStartDate", $$v)},expression:"formData.priceStartDate"}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"startDate-feedback"}},[_vm._v(" "+_vm._s(_vm.sytemMessages.startDateRequired)+" ")])],1)]),_c('div',{staticClass:"col-sm-12 col-md-6"},[_c('div',{staticClass:"mb-4",attrs:{"id":"endDate","role":"group"}},[_c('label',{staticClass:"form-label",attrs:{"for":"endDate"}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.end_date-label`)))]),_c('div',{staticClass:"flex w-full",class:{ 'is-invalid': _vm.$v.formData.priceEndDate.$error }},[_c('v-date-picker',{ref:"priceEndDate",staticClass:"flex-grow",attrs:{"locale":_vm.selected_language,"masks":_vm.masks,"popover":{ visibility: 'click' },"model-config":_vm.modelConfig,"disabled-dates":[
                  {
                    start: null,
                    end: _vm.yesterday,
                  },
                ],"available-dates":{
                  start:
                    _vm.formData.priceEndDate != ''
                      ? new Date(_vm.formData.priceEndDate)
                      : new Date(),
                  end:
                    _vm.formData.priceEndDate != ''
                      ? new Date(_vm.formData.priceEndDate)
                      : null,
                }},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue }){return [_c('div',{staticClass:"d-flex custom-datepicker"},[_c('input',{staticClass:"form-control bg-white rounded-top-right-0 rounded-bottom-right-0",class:{
                        'is-invalid': _vm.$v.formData.priceEndDate.$error,
                      },attrs:{"placeholder":_vm.dateFormat,"readonly":""},domProps:{"value":inputValue},on:{"click":_vm.showEndDatePicker}}),_c('div',{staticClass:"picker-icon",class:{
                        'is-invalid': _vm.$v.formData.priceEndDate.$error,
                      },attrs:{"slot":"afterDateInput","role":"button"},on:{"click":_vm.showEndDatePicker},slot:"afterDateInput"},[_c('i',{staticClass:"icon-date"})])])]}}],null,false,3035473983),model:{value:(_vm.formData.priceEndDate),callback:function ($$v) {_vm.$set(_vm.formData, "priceEndDate", $$v)},expression:"formData.priceEndDate"}})],1),_c('b-form-invalid-feedback',{attrs:{"id":"priceEndDate-feedback"}},[_vm._v(" "+_vm._s(_vm.$t(`flat-flyer-pricing.end_date-invalid-error-msg`))+" ")])],1)])])]):_vm._e(),(_vm.showFormPreview)?_c('preview-form',{attrs:{"formName":_vm.formData.name,"showProductGroups":"","showStartDate":"","startDate":_vm._f("formatedDate")(_vm.formData.priceStartDate),"showEndDate":"","endDate":_vm._f("formatedDate")(_vm.formData.priceEndDate),"moduleSlug":"flat-flyer-pricing","nameLabel":_vm.$t(`flat-flyer-pricing.name-label`),"siteLabel":_vm.$t(`flat-flyer-pricing.selected_site-label`),"chooseProductLabel":_vm.$t(`flat-flyer-pricing.choose_product-label`),"startDateLabel":_vm.$t(`flat-flyer-pricing.start_date-label`),"endDateLabel":_vm.$t(`flat-flyer-pricing.end_date-label`)}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row-reverse"},[(_vm.showForm)?_c('b-button',{attrs:{"id":"find-products-btn","variant":"brand-primary"},on:{"click":_vm.handleFindProduct}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.find_product-btn-label`))+" ")]):_vm._e(),(
          _vm.showFormPreview &&
          _vm.isGridEditable == false &&
          _vm.showFilteredProducts == true
        )?_c('b-button',{attrs:{"id":"edit-products-grid-btn","variant":"brand-secondary"},on:{"click":_vm.editProductGrid}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.edit_price-btn-label`))+" ")]):_vm._e(),(
          _vm.showFormPreview &&
          _vm.isGridEditable == true &&
          _vm.showFilteredProducts == true
        )?_c('b-button',{staticClass:"mr-3",attrs:{"id":"validate-btn","variant":"brand-primary"},on:{"click":_vm.handleValidateProduct}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.validate-btn-label`)))]):_vm._e(),(
          _vm.showFormPreview &&
          _vm.isGridEditable == false &&
          _vm.showFilteredProducts == false &&
          _vm.showValidatedProducts == true
        )?_c('b-button',{attrs:{"id":"submit-btn","variant":"brand-primary"},on:{"click":_vm.submitForm}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.submit-btn-label`)))]):_vm._e(),(_vm.showFormPreview)?_c('b-button',{staticClass:"mr-3",attrs:{"id":"edit-btn","variant":"brand-secondary"},on:{"click":_vm.handleEdit}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.edit_find-btn-label`)))]):_vm._e()],1)],1),_c('b-alert',{staticClass:"mt-4",attrs:{"show":_vm.editColumnDismissCountDown,"variant":"primary","dismissible":""},on:{"dismissed":function($event){_vm.editColumnDismissCountDown = 0},"dismiss-count-down":_vm.countDownChangedEditColumn}},[_c('i',{staticClass:"icon-info mr-1"}),_vm._v(" "+_vm._s(_vm.$t(`flat-flyer-pricing.edittable_column-msg`))+" ")]),_c('b-alert',{staticClass:"mt-4",attrs:{"show":_vm.invalidProdcutsDismissCountDown,"variant":"danger","dismissible":""},on:{"dismissed":function($event){_vm.invalidProdcutsDismissCountDown = 0},"dismiss-count-down":_vm.countDownChangedInvalidProdcuts}},[_c('i',{staticClass:"icon-error mr-1"}),_vm._v(" "+_vm._s(_vm.$t(`flat-flyer-pricing.invalid_data-error-msg`))+" ")]),(
      _vm.productPreviewFailed || _vm.showFilteredProducts || _vm.showValidatedProducts
    )?_c('b-card',{staticClass:"mt-4"},[(_vm.productPreviewFailed)?_c('product-preview-failed'):_vm._e(),(_vm.showFilteredProducts)?_c('product-editable-grid',{attrs:{"formName":"flat-flyer-pricing","isGridEditable":_vm.isGridEditable,"fileTitle":_vm.formData.name,"moduleSlug":"flat-flyer-pricing"},on:{"filteredProducts":_vm.setFilteredProducts}}):_vm._e(),(_vm.showValidatedProducts)?_c('product-preview',{attrs:{"formName":"flat-flyer-pricing","fileTitle":_vm.formData.name,"moduleSlug":"flat-flyer-pricing"}}):_vm._e()],1):_vm._e(),_c('b-modal',{ref:"success-modal",attrs:{"dialog-class":"success-modal","centered":"","hide-header":"","hide-footer":""}},[_c('div',{staticClass:"d-flex justify-content-center flex-column align-items-center text-center"},[_c('i',{staticClass:"font-50 icon-completed mb-2"}),_c('div',{staticClass:"message-detail"},[_c('h5',{staticClass:"msg-title"},[_vm._v(" "+_vm._s(_vm.$t(`flat-flyer-pricing.success_message-title`))+" ")]),_c('i18n',{staticClass:"msg-desc m-0",attrs:{"path":`flat-flyer-pricing.success_message-desc`,"tag":"p"}},[_c('b-link',{attrs:{"to":"/pricing-history"}},[_vm._v(_vm._s(_vm.$t(`flat-flyer-pricing.success_message-desc_term`)))])],1)],1)])])],1):_c('div',[_c('b-card',{staticClass:"mt-4"},[_c('form-success',[_c('h5',{staticClass:"msg-title"},[_vm._v(" "+_vm._s(_vm.$t(`flat-flyer-pricing.success_message-title`))+" ")]),_c('i18n',{staticClass:"msg-desc m-0",attrs:{"path":`flyer-pricing-import.success_message-desc`,"tag":"p"}},[_c('b-link',{attrs:{"to":"/pricing-history"}},[_vm._v(_vm._s(_vm.$t(`flyer-pricing-import.success_message-desc_term`)))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }